import { useEffect, useState } from 'react';

import { IUserAccountInfo } from '@/types/components/pages/settings/UserProfile';
import { CompanyResponse, fetchCompany } from '@/utils/api/user';

type Error = {
    message: string;
};

const useCompany = (user?: IUserAccountInfo, refreshIndex?: number | null) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [company, setCompany] = useState<CompanyResponse | null>(null);
    const userEmail = user ? user.email : '';

    useEffect(() => {
        // Don't start fetching anything unless user is fully available
        if (!user || !user.email) {
            return;
        }
        setIsLoading(true);

        // Fetch company details from backend
        fetchCompany()
            .then(fetchedCompany => {
                setCompany(fetchedCompany);
                setIsLoading(false);
            })
            .catch(thrownError => {
                setError(thrownError);
                setIsLoading(false);
            });
    }, [userEmail, refreshIndex]);

    return { company, error, isLoading };
};

export default useCompany;
