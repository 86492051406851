import * as React from 'react';

import { AutocompleteApi } from '@algolia/autocomplete-core/dist/esm/types';
import { InternalAutocompleteSource } from '@algolia/autocomplete-js';
import { SafeStrapiEntity, Flashnote, Article } from '@blockworks/platform/src/api/strapi/models';

import ResearchItem from '@/components/search/global/results/ResearchItem';

type ResearchItemType = SafeStrapiEntity<Article | Flashnote>;

type ResearchResultsType = {
    items: ResearchItemType[];
    autocomplete: AutocompleteApi<ResearchItemType>;
    source: InternalAutocompleteSource<ResearchItemType>;
    onSelect: (item: ResearchItemType) => void;
};

const ResearchResults = (props: ResearchResultsType) => {
    const { items: i, autocomplete, source, onSelect } = props;
    const items = i ?? [];

    return (
        <>
            {items.length && (
                <ul className="" {...autocomplete.getListProps()}>
                    {items.map((item: ResearchItemType) => {
                        const itemProps = autocomplete.getItemProps({
                            item,
                            source,
                        });

                        return (
                            <li aria-selected={itemProps['aria-selected']} key={item.objectID}>
                                <ResearchItem onSelect={onSelect} item={item} selected={itemProps['aria-selected']} />
                            </li>
                        );
                    })}
                </ul>
            )}
        </>
    );
};

export default ResearchResults;
