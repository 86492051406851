// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        name: 'Newsletter Signup',
        href: 'https://blockworks.co/newsletter/research',
    },
    {
        name: 'Podcast: 0xResearch',
        href: 'https://blockworks.co/podcast/0xresearch',
    },
    {
        name: 'Privacy Policy',
        href: '/privacy-policy',
    },
    {
        name: 'Terms of Use',
        href: '/terms-of-use',
    },
    {
        name: 'Support',
        href: '/support',
    },
];
