import {
    BoltIcon,
    ChartBarIcon,
    CogIcon,
    CurrencyDollarIcon,
    GlobeAltIcon,
    HomeIcon,
    NewspaperIcon,
    RssIcon,
    UsersIcon,
} from '@heroicons/react/24/outline';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        name: 'Home',
        href: '/',
        icon: HomeIcon,
    },
    {
        name: 'Assets',
        href: '/assets',
        icon: CurrencyDollarIcon,
    },
    {
        name: 'Research',
        href: '/research',
        icon: RssIcon,
    },
    {
        name: 'Flashnotes',
        href: '/flashnotes',
        icon: BoltIcon,
    },
    {
        name: 'Analytics',
        href: '/analytics',
        icon: ChartBarIcon,
    },
    {
        name: 'GovHub',
        href: '/governance',
        icon: GlobeAltIcon,
    },
    {
        name: 'News',
        href: '/news',
        icon: NewspaperIcon,
        featureFlag: 'news',
    },
    {
        name: 'Analysts',
        href: '/team',
        icon: UsersIcon,
    },
    {
        name: 'Glossary',
        href: '/glossary',
        icon: CogIcon,
    },
];
