import React from 'react';

type ResultsHeaderProps = {
    title: string;
};

const ResultsHeader = (props: ResultsHeaderProps) => {
    return <h3 className="py-2 px-4 text-xs font-semibold text-gray-600 uppercase md:p-4">{props.title}</h3>;
};

export default ResultsHeader;
