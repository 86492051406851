import React from 'react';

import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Transition } from '@tailwindui/react';
import { toast, Toaster } from 'react-hot-toast';
import useDeferredRender from '@core/utils/hooks/useDeferredRender';

import { classNames } from '@/utils/functions/class-names';

const Notifications = () => {
    const ready = useDeferredRender();
    if (!ready) return <></>;

    return (
        <Toaster>
            {t => (
                <Transition
                    appear
                    show={t.visible}
                    className="transform"
                    enter="transition-all duration-150"
                    enterFrom="opacity-0 scale-50"
                    enterTo="opacity-100 scale-100"
                    leave="transition-all duration-150"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-75"
                >
                    <div
                        className={classNames(
                            'flex flex-row items-center w-96 bg-dark px-4 py-6 text-purple-50 hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1 rounded-xl relative transition-all duration-500 ease-in-out',
                            t.visible ? 'top-0' : '-top-96',
                        )}
                    >
                        <div className="text-xl">
                            <InformationCircleIcon className="h-6 w-6" />
                        </div>
                        <div className="flex flex-col items-start justify-center ml-4 cursor-default">
                            <p className="text-sm text-gray-400 leading-relaxed tracking-wider text-left">
                                <>{t.message}</>
                            </p>
                        </div>
                        <div
                            className={'absolute top-2 right-2 cursor-pointer text-lg;'}
                            onClick={() => toast.dismiss(t.id)}
                        >
                            <XMarkIcon className="h-5 w-5" />
                        </div>
                    </div>
                </Transition>
            )}
        </Toaster>
    );
};

export default Notifications;
