export default <ResponseType>(response: any): ResponseType => {
    if (response.error && response.userMessage) {
        throw new Error(`${response.error}: ${response.userMessage}`);
    }

    if (response.error) {
        throw new Error(response.error);
    }

    // eslint-disable-next-line no-underscore-dangle
    if (response._meta && !response._meta?.success) {
        throw new Error('Unknown error fetching data.');
    }

    // Handling various api exceptions
    if (response.data?.status === 0 && response.data?.response) {
        throw new Error(response.data.response);
    }

    return response.data;
};
