import * as React from 'react';

import { AutocompleteApi } from '@algolia/autocomplete-core/dist/esm/types';
import { InternalAutocompleteSource } from '@algolia/autocomplete-js';

import ProposalItem, { ProposalItemType } from '@/components/search/global/results/ProposalItem';

type ProposalResultsType = {
    items: ProposalItemType[];
    autocomplete: AutocompleteApi<ProposalItemType>;
    source: InternalAutocompleteSource<ProposalItemType>;
    onSelect: (item: ProposalItemType) => void;
};

const ProposalResults = (props: ProposalResultsType) => {
    const { items, autocomplete, source, onSelect } = props;

    return (
        <>
            {items.length > 0 && (
                <ul className="" {...autocomplete.getListProps()}>
                    {items.map((item: ProposalItemType) => {
                        const itemProps = autocomplete.getItemProps({
                            item,
                            source,
                        });

                        return (
                            <li aria-selected={itemProps['aria-selected']} key={item.objectID}>
                                <ProposalItem
                                    title={item.title}
                                    assetCode={item.assetCode}
                                    image={item.image}
                                    objectID={item.objectID}
                                    category={item.category}
                                    importance={item.importance}
                                    selected={itemProps['aria-selected']}
                                    onSelect={onSelect}
                                />
                            </li>
                        );
                    })}
                </ul>
            )}
        </>
    );
};

export default ProposalResults;
