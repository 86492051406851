// @ts-nocheck
import React, { MutableRefObject } from 'react';

import { AutocompleteState, BaseItem, createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';
import { useRouter } from 'next/router';
import { useTrack } from '@core/utils/contexts/analytics/AnalyticsProvider';

import AssetItem, { AssetItemType } from '@/components/search/global/results/AssetItem';
import AssetResults from '@/components/search/global/results/AssetResults';
import type { ProposalItemType } from '@/components/search/global/results/ProposalItem';
import ProposalResults from '@/components/search/global/results/ProposalResults';
import ResearchItem, { ResearchItemType } from '@/components/search/global/results/ResearchItem';
import ResearchResults from '@/components/search/global/results/ResearchResults';
import ResultsHeader from '@/components/search/global/results/ResultsHeader';
import {
    createLocalStorageRecentlyViewedItems,
    RecentlyViewedRecord,
} from '@/components/search/utils/recentlyViewedItemsPlugin';
import ResearchConfig from '@/ResearchConfig';
import { classNames } from '@/utils/functions/class-names';
import { AnalyticsEvent } from '@/utils/enums/eventsTracking';

type AutocompleteProps = {
    query: string;
    className: string;
    refs: MutableRefObject<null>[];
};

type AutocompleteStateType = AutocompleteState<BaseItem>;

const Autocomplete = (props: AutocompleteProps) => {
    const { className, refs } = props;
    const [searchParent, userParent] = refs;
    const router = useRouter();
    const track = useTrack();
    const [autocompleteState, setAutocompleteState] = React.useState<AutocompleteStateType>({
        isOpen: false,
        activeItemId: null,
        query: '',
        completion: null,
        collections: [],
        status: 'loading',

        // @ts-ignore
        context: undefined,
    });
    const searchClient = algoliasearch(ResearchConfig.algolia.appId, ResearchConfig.algolia.key);
    const recentlyViewedItems = createLocalStorageRecentlyViewedItems({
        key: 'SEARCH_RECENTLY_VIEWED',
        limit: 3,
    });

    const autocomplete = React.useMemo(
        () =>
            createAutocomplete({
                id: 'search',
                openOnFocus: true,
                plugins: [recentlyViewedItems],
                onStateChange: ({ state }) => {
                    // (2) Synchronize the Autocomplete state with the React state.
                    setAutocompleteState(state);
                },
                // @ts-ignore
                getSources: ({ query }) => {
                    if (!query) {
                        return [];
                    }

                    return [
                        // (3) Use an Algolia index source.
                        {
                            sourceId: 'Research',
                            getItemInputValue: ({ item }) => item.query,
                            getItems: params =>
                                getAlgoliaResults<ResearchItemType>({
                                    searchClient,
                                    queries: [
                                        {
                                            indexName: 'research',
                                            query: params.query,
                                            params: {
                                                clickAnalytics: true,
                                                hitsPerPage: 4,
                                                highlightPreTag: '<mark class="font-bold">',
                                                highlightPostTag: '</mark>',
                                            },
                                        },
                                    ],
                                }),
                            onSelect: ({ item }: { item: ResearchItemType }) => {
                                recentlyViewedItems.data?.addItem({
                                    item,
                                    sourceId: 'Research',
                                });
                            },
                            getItemUrl: ({ item }: { item: ResearchItemType }) => `/research/${item.slug}`,
                            templates: {
                                header: () => <ResultsHeader title="Research" />,
                            },
                        },
                        {
                            sourceId: 'Assets',
                            getItemInputValue: ({ item }) => item.query,
                            getItems: params =>
                                getAlgoliaResults<AssetItemType>({
                                    searchClient,
                                    queries: [
                                        {
                                            indexName: 'assets',
                                            query: params.query,
                                            params: {
                                                clickAnalytics: true,
                                                hitsPerPage: 4,
                                                highlightPreTag: '<mark class="font-bold">',
                                                highlightPostTag: '</mark>',
                                            },
                                        },
                                    ],
                                }),
                            getItemUrl: ({ item }: { item: AssetItemType }) => `/assets/${item.code.toLowerCase()}`,
                            onSelect: ({ item }: { item: AssetItemType }) => {
                                recentlyViewedItems.data?.addItem({
                                    item,
                                    sourceId: 'Assets',
                                });
                            },
                            templates: {
                                header: () => <ResultsHeader title="Assets" />,
                            },
                        },
                        {
                            sourceId: 'Proposal',
                            getItemInputValue: ({ item }) => item.query,
                            getItems: params =>
                                getAlgoliaResults<ProposalItemType>({
                                    searchClient,
                                    queries: [
                                        {
                                            indexName: 'proposal',
                                            query: params.query,
                                            params: {
                                                clickAnalytics: true,
                                                hitsPerPage: 4,
                                                highlightPreTag: '<mark class="font-bold">',
                                                highlightPostTag: '</mark>',
                                            },
                                        },
                                    ],
                                }),
                            onSelect: ({ item }: { item: ProposalItemType }) => {
                                recentlyViewedItems.data?.addItem({
                                    item,
                                    sourceId: 'Proposal',
                                });
                            },
                            getItemUrl: ({ item }: { item: ProposalItemType }) =>
                                `/assets/${item.assetCode}/governance/${item.objectID}`,
                            templates: {
                                header: () => <ResultsHeader title="Proposal" />,
                            },
                        },
                    ];
                },
            }),
        [],
    );

    // Prepare input and panel props from autocomplete
    const inputProps = autocomplete.getInputProps({ inputElement: null });
    const panelProps = autocomplete.getPanelProps({});

    const growSearchBar = () => {
        searchParent?.current?.classList?.add('pr-4', 'sm:pr-0');

        [userParent].forEach(div => {
            div?.current?.classList?.add('hidden', 'sm:flex');
        });
    };

    const shrinkSearchBar = () => {
        searchParent?.current?.classList?.remove('pr-4', 'sm:pr-0');

        [userParent].forEach(div => {
            div?.current?.classList?.remove('hidden', 'sm:flex');
        });
    };

    // @ts-ignore
    const inputElement = (
        <input
            {...inputProps}
            name="search-field"
            className="h-8 w-full z-30 rounded-xl bg-transparent text-base block pl-10 pr-3 py-1.5 border-transparent placeholder-gray-300 focus:placeholder-gray-600 focus:bg-white focus:ring-0 focus:border-transparent lg:text-sm"
            placeholder="Search Blockworks Research"
            onClick={() => track(AnalyticsEvent.clickedSearchBar, { source: 'global-search' })}
            onFocus={growSearchBar}
            onBlur={shrinkSearchBar}
        />
    );

    /**
     * @param url
     */
    const navigateToUrl = (url: string | undefined) => {
        if (url) {
            autocomplete.setIsOpen(false);
            router.push(url).catch(() => {});
        }
    };

    // @ts-ignore
    const autocompleteResults = (
        <div className="z-30 overflow-y-auto max-h-72 text-sm font-medium sm:max-h-screen" {...panelProps}>
            {autocompleteState.isOpen &&
                autocompleteState.collections.map((collection, index) => {
                    const { source, items } = collection;
                    const { sourceId } = source;

                    if (items.length === 0) {
                        return null;
                    }

                    let resultList;
                    if (sourceId === 'Assets') {
                        // @ts-ignore
                        resultList = (
                            <AssetResults
                                items={items}
                                autocomplete={autocomplete}
                                source={source}
                                onSelect={item => navigateToUrl(source.getItemUrl({ item, state: autocompleteState }))}
                            />
                        );
                    } else if (sourceId === 'Research') {
                        // @ts-ignore
                        resultList = (
                            <ResearchResults
                                items={items}
                                autocomplete={autocomplete}
                                source={source}
                                onSelect={item => navigateToUrl(source.getItemUrl({ item, state: autocompleteState }))}
                            />
                        );
                    } else if (sourceId === 'Proposal') {
                        // @ts-ignore
                        resultList = (
                            <ProposalResults
                                items={items}
                                autocomplete={autocomplete}
                                source={source}
                                onSelect={item => navigateToUrl(source.getItemUrl({ item, state: autocompleteState }))}
                            />
                        );
                    } else if (sourceId === 'recentSearchesPlugin') {
                        // @ts-ignore
                        resultList = items.map((record: RecentlyViewedRecord) => {
                            if (record.sourceId === 'Assets') {
                                return (
                                    <AssetItem
                                        key={record.item.slug}
                                        {...(record.item as AssetItemType)}
                                        onSelect={item =>
                                            navigateToUrl(source.getItemUrl({ item, state: autocompleteState }))
                                        }
                                    />
                                );
                            }
                            if (record.sourceId === 'Research') {
                                return (
                                    <ResearchItem
                                        key={record.item.slug}
                                        {...(record.item as ResearchItemType)}
                                        onSelect={item =>
                                            navigateToUrl(source.getItemUrl({ item, state: autocompleteState }))
                                        }
                                    />
                                );
                            }

                            return null;
                        });
                    }

                    // @ts-ignore
                    const header = source.templates.header();

                    return (
                        <div key={`source-${index}`} className="z-30 mb-2 divide-y divide-gray-200 rounded-xl">
                            {header}
                            {resultList}
                        </div>
                    );
                })}
        </div>
    );

    return (
        <div
            {...autocomplete.getRootProps({})}
            className={classNames(
                'z-30 rounded-xl text-xs shadow-xl',
                autocompleteState.isOpen
                    ? 'bg-white focus:outline-none focus:ring-0 focus:border-purple-100'
                    : 'bg-search',
                className,
            )}
        >
            {inputElement}
            {autocompleteResults}
        </div>
    );
};

export default Autocomplete;
