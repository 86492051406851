import * as React from 'react';

import Link from '@core/components/display/links/Link';
import { useRouter } from 'next/router';
import { useTrack } from '@core/utils/contexts/analytics/AnalyticsProvider';

import primaryNavigation from '@/layout/containers/DefaultContainer/navigation/primaryNavigation';
import secondaryNavigation from '@/layout/containers/DefaultContainer/navigation/secondaryNavigation';
import { FeatureFlags } from '@/types/FeatureFlags';
import { classNames } from '@/utils/functions/class-names';
import { AnalyticsEvent } from '@/utils/enums/eventsTracking';

interface MainNavigationProps {
    onClickNavItem?: () => void;
    featureFlags?: FeatureFlags;
}

const MainNavigation = (props: MainNavigationProps) => {
    const { onClickNavItem, featureFlags } = props;
    const { pathname } = useRouter();
    const track = useTrack();

    const onClick = (navName: string) => {
        track(AnalyticsEvent.clickedNavItem, {
            source: navName,
            props: { action: navName },
        });

        onClickNavItem && onClickNavItem();
    };

    return (
        <nav
            className="mb-4 flex-1 flex flex-col justify-between overflow-y-auto"
            style={{ minHeight: '100pt' }}
            aria-label="Sidebar"
        >
            <ol>
                {primaryNavigation.map(item => {
                    const { name, icon: Icon, href } = item;

                    // TODO: We can make it more scalable once we have more use cases for the feature-flags
                    if (item.featureFlag && !featureFlags?.[item.featureFlag as keyof typeof featureFlags]) return null;

                    const isRoot = pathname === '/';
                    const isCurrent = isRoot ? href === '/' : pathname.startsWith(href) && href !== '/';
                    return (
                        <li key={`main-nav-${href}`} aria-current={isCurrent ? 'page' : undefined}>
                            <Link
                                href={href}
                                onClick={() => onClick(name)}
                                className={classNames(
                                    isCurrent ? 'bg-[#2F2844] text-white' : 'text-gray-100',
                                    'px-8 py-2 my-1 mx-2 hover:text-white hover:bg-[#2F2844] group flex items-center text-sm font-medium leading-6 rounded-full cursor-pointer',
                                )}
                            >
                                {Icon && <Icon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />}
                                <span>{name}</span>
                            </Link>
                        </li>
                    );
                })}
            </ol>
            <ol>
                {secondaryNavigation.map(item => {
                    const { name, href } = item;

                    const isExternalLink = href.startsWith('http');
                    const styles =
                        'px-5 py-0.5 mx-5 text-gray-100 font-light hover:text-white hover:bg-[#2F2844] group flex items-center text-xs font-medium leading-6 rounded-full cursor-pointer';

                    const optionalProps = isExternalLink ? { target: '_blank' } : {};
                    return (
                        <li key={`secondary-nav-${item.href}`}>
                            <Link href={href} onClick={onClickNavItem} className={styles} {...optionalProps}>
                                <span>{name}</span>
                            </Link>
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default MainNavigation;
