import { ReactNode } from 'react';

import useDeferredRender from '@core/utils/hooks/useDeferredRender';

type IContentProps = {
    children?: ReactNode;
    className?: string;
};

const Content = (props: IContentProps) => {
    const ready = useDeferredRender();
    return (
        <main className={`${props.className} flex-1`}>
            <section className="content m-2 lg:m-4 xl:m-8 min-h-[calc(100vh-6rem)]">
                <>{ready ? props.children : null}</>
            </section>
        </main>
    );
};

export default Content;
