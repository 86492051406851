import * as React from 'react';
import { useEffect, useState } from 'react';

import { HistoryProvider } from '@core/utils/contexts/HistoryContext';
import { createClient } from '@vercel/edge-config';
import App, { AppContext, AppProps } from 'next/app';
import Router from 'next/router';
import { SessionProvider } from 'next-auth/react';
import NProgress from 'nprogress';
import TagManager from 'react-gtm-module';
import { SWRConfig } from 'swr';

import ResearchConfig from '@/ResearchConfig';
import Notifications from '@/components/display/Notifications';
import Analytics from '@/layout/containers/Analytics';
import DefaultContainer from '@/layout/containers/DefaultContainer';
import { DefaultMeta } from '@/layout/head/DefaultMeta';
import { UserProvider } from '@/utils/contexts/UserContext';
import type { FeatureFlags } from '@/types/FeatureFlags.d';
// Import global stylesheet, includes Tailwind CSS
import '@/assets/styles/global.css';
import '@/assets/styles/ghost.css';
import '@/assets/styles/nprogress.css';

// Content Component's interfaces
export type IComponent<T> = React.FC<T> & {
    Layout?: typeof DefaultContainer | null;
    Meta?: typeof DefaultMeta | null;
};

// Meta description interface
type MetaDescription = {
    title: string;
    description: string;
    image?: string;
};

// Failure properties
type PagePropsFailure = {
    message: string;
    code: number;
};

// Pages internal properties
export type PageProps = object & {
    // Optional meta data, can be overridden from any page's server side rendering result
    meta?: MetaDescription | null;
    // Optional error context if there was an error in the SSR process
    failure?: PagePropsFailure | null;
    // SSR/SWR fallback, see https://swr.vercel.app/docs/with-nextjs
    fallback?: object | object[];
    session?: any;
};

// Global App's properties
export type BlockworksResearchAppProps = AppProps & {
    // Content component (from /pages/)
    Component: IComponent<any>;
    // Page properties, usually holds initial data from SSR and meta information
    pageProps: PageProps;
    // Feature flags coming in through next.js Edge Config
    featureFlags?: FeatureFlags;
};

const FEATURE_FLAGS_KEY = 'blockworks.features';

// Provide default meta tags
const MetaDefault: MetaDescription = {
    title: ResearchConfig.site_name,
    description: ResearchConfig.description,
};

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const BlockworksResearchApp = (appProps: BlockworksResearchAppProps) => {
    // Receive content Component and pageProps
    const { Component, pageProps } = appProps;
    let { featureFlags } = appProps;

    const [session, setSession] = useState(pageProps.session);

    // DefaultContainer and DefaultMeta is used if not otherwise specified
    const Layout = Component.Layout || DefaultContainer;
    const Meta = Component.Meta || DefaultMeta;

    // Fetch meta properties from pageProps or use the default
    const metaProps = pageProps.meta || MetaDefault;

    // Set/get flags to sessionStorage only on client side
    if (typeof window !== 'undefined') {
        if (featureFlags) {
            sessionStorage.setItem(FEATURE_FLAGS_KEY, JSON.stringify(featureFlags));
        } else if (!featureFlags) {
            featureFlags = JSON.parse(sessionStorage.getItem(FEATURE_FLAGS_KEY) || '{}');
        }
    }

    // Enable google tag manager
    useEffect(() => {
        if (process.env.GOOGLE_TAG_MANAGER_ID) {
            TagManager.initialize({
                gtmId: process.env.GOOGLE_TAG_MANAGER_ID,
                dataLayerName: 'PageDataLayer',
            });
        }
    }, []);

    if (process.env.GOOGLE_TAG_MANAGER_ID && typeof window !== 'undefined') {
        TagManager.dataLayer({
            dataLayer: {
                userId: session ? session.userId : 'logged-out',
                userProject: 'production',
            },
            dataLayerName: 'PageDataLayer',
        });
    }

    // Return render output
    return (
        <SessionProvider refetchInterval={300} session={session}>
            <SWRConfig value={pageProps}>
                <Analytics meta={metaProps}>
                    <UserProvider>
                        <Layout meta={<Meta {...metaProps} />} featureFlags={featureFlags}>
                            <HistoryProvider>
                                <Component {...pageProps} updateSession={setSession} />
                                <Notifications />
                            </HistoryProvider>
                        </Layout>
                    </UserProvider>
                </Analytics>
            </SWRConfig>
        </SessionProvider>
    );
};

BlockworksResearchApp.getInitialProps = async (appContext: AppContext) => {
    const initialProps: any = await App.getInitialProps(appContext);

    let props = initialProps;

    if (typeof window === 'undefined') {
        try {
            const edgeConfig = createClient(process.env.EDGE_CONFIG);
            const featureFlags = await edgeConfig.get('features');

            props = {
                ...initialProps,
                featureFlags,
            };
        } catch (e) {
            console.warn('Could not fetch edge config.');
        }
    }

    return props;
};

export default BlockworksResearchApp;
