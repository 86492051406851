import { ReactNode } from 'react';

import Image from 'next/image';
import Link from '@core/components/display/links/Link';

import { classNames } from '../../../utils/functions/class-names';

type LabelType = {
    children: ReactNode;
    slug?: string;
    className?: string;
    href?: string;
    blue?: boolean;
    green?: boolean;
    red?: boolean;
    purple?: boolean;
    smaller?: boolean;
    small?: boolean;
    big?: boolean;
    target?: string;
    hasLogo?: boolean;
};

const Label = (props: LabelType) => {
    const { children, slug, className, href, blue, green, red, purple, smaller, big, target, hasLogo } = props;

    const logo: JSX.Element = (
        <span className="flex items-center mr-2">
            <Image
                src={`/images/tokens/${children?.toString().toLowerCase()}.svg`}
                height={18}
                width={18}
                alt={children?.toString().toLowerCase() ?? ''}
            />
        </span>
    );

    let colorScheme = 'bg-gray-100 text-text-dark hover:border-gray-200';
    if (blue) {
        colorScheme = 'bg-blue-100 text-blue-900 hover:border-blue-200';
    } else if (purple) {
        colorScheme = 'bg-purple-100 text-purple-900 hover:border-purple-200';
    } else if (green) {
        colorScheme = 'bg-green-100 text-green-900 hover:border-green-200';
    } else if (red) {
        colorScheme = 'bg-red-100 text-red-900 hover:border-red-200';
    }

    let size = 'px-2.5 py-0.5 text-xs';
    if (smaller) {
        size = 'px-2 py-0 text-xs';
    } else if (big) {
        size = 'px-2.5 py-1 text-sm';
    }

    if (!href) {
        return (
            <span
                className={classNames(
                    'inline-flex items-center rounded-full font-medium border border-transparent cursor-default',
                    className || '',
                    colorScheme,
                    size,
                )}
            >
                {hasLogo && logo}
                {children}
            </span>
        );
    }

    return (
        <Link
            href={{
                pathname: href,
                query: { tags: slug },
            }}
            target={target}
            className={classNames(
                'inline-flex items-center rounded-full font-medium border border-transparent',
                className || '',
                colorScheme,
                size,
            )}
        >
            {hasLogo && logo}
            {children}
        </Link>
    );
};

export default Label;
