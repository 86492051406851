import * as React from 'react';

type ProfilePictureSize = 'small' | 'default' | 'big';

type UserProfilePictureProps = {
    size: ProfilePictureSize;
    person: {
        name: string;
        imageUrl: string;
        firstname?: string;
    };
    preview?: string | null | undefined;
};

const UserProfilePicture = (props: UserProfilePictureProps) => {
    const { person, preview, size } = props;

    const sizeClass =
        (size === 'big' && 'h-128 w-128') || (size === 'default' && 'h-40 w-40') || (size === 'small' && 'h-8 w-8');

    if (!person.name) {
        return (
            <div className="mt-2">
                <img
                    className={`${sizeClass} rounded-full relative bg-primary`}
                    src={person.imageUrl}
                    alt={preview || ''}
                />
            </div>
        );
    }

    return (
        <div>
            {person.imageUrl !== '' ? (
                <img
                    className={`${sizeClass} rounded-full relative bg-primary`}
                    src={person.imageUrl}
                    alt={person.name}
                />
            ) : (
                <div
                    className={`flex justify-center items-center bg-primary text-gray-300 p-4 rounded-full ${sizeClass}`}
                >
                    <span className="">{person.name.charAt(0)}</span>
                </div>
            )}
        </div>
    );
};

export default UserProfilePicture;
