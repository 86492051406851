import * as React from 'react';

import Link from '@core/components/display/links/Link';

export type SocialsItem = {
    name: string;
    href: string;
    icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
};

const SOCIALS = [
    {
        name: 'Twitter',
        href: 'https://twitter.com/blockworksres',
        icon: (sProps: React.SVGProps<SVGSVGElement>) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...sProps}>
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
        ),
    },
    {
        name: 'Discord',
        href: 'https://discord.gg/V3ZQwqV6Cj',
        icon: (sProps: React.SVGProps<SVGSVGElement>) => (
            <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...sProps}>
                <path d="M20.317 3.9916C18.7873 3.25493 17.147 2.71218 15.4319 2.40133C15.4007 2.39533 15.3695 2.41032 15.3534 2.44031C15.1424 2.83412 14.9087 3.34788 14.7451 3.7517C12.9004 3.46184 11.0652 3.46184 9.25832 3.7517C9.09465 3.33891 8.85248 2.83412 8.64057 2.44031C8.62448 2.41132 8.59328 2.39633 8.56205 2.40133C6.84791 2.71119 5.20756 3.25394 3.67694 3.9916C3.66368 3.9976 3.65233 4.0076 3.64479 4.02058C0.533392 8.89934 -0.31895 13.6582 0.0991801 18.358C0.101072 18.381 0.11337 18.403 0.130398 18.417C2.18321 19.9993 4.17171 20.9598 6.12328 21.5965C6.15451 21.6065 6.18761 21.5945 6.20748 21.5675C6.66913 20.9059 7.08064 20.2082 7.43348 19.4745C7.4543 19.4315 7.43442 19.3805 7.39186 19.3636C6.73913 19.1037 6.1176 18.7868 5.51973 18.427C5.47244 18.398 5.46865 18.327 5.51216 18.293C5.63797 18.1941 5.76382 18.0911 5.88395 17.9872C5.90569 17.9682 5.93598 17.9642 5.96153 17.9762C9.88928 19.8583 14.1415 19.8583 18.023 17.9762C18.0485 17.9632 18.0788 17.9672 18.1015 17.9862C18.2216 18.0901 18.3475 18.1941 18.4742 18.293C18.5177 18.327 18.5149 18.398 18.4676 18.427C17.8697 18.7938 17.2482 19.1037 16.5945 19.3626C16.552 19.3796 16.533 19.4315 16.5538 19.4745C16.9143 20.2072 17.3258 20.9048 17.7789 21.5665C17.7978 21.5945 17.8319 21.6065 17.8631 21.5965C19.8241 20.9598 21.8126 19.9993 23.8654 18.417C23.8834 18.403 23.8948 18.382 23.8967 18.359C24.3971 12.9255 23.0585 8.20567 20.3482 4.02158C20.3416 4.0076 20.3303 3.9976 20.317 3.9916ZM8.02002 15.4963C6.8375 15.4963 5.86313 14.3569 5.86313 12.9575C5.86313 11.5581 6.8186 10.4187 8.02002 10.4187C9.23087 10.4187 10.1958 11.5681 10.1769 12.9575C10.1769 14.3569 9.22141 15.4963 8.02002 15.4963ZM15.9947 15.4963C14.8123 15.4963 13.8379 14.3569 13.8379 12.9575C13.8379 11.5581 14.7933 10.4187 15.9947 10.4187C17.2056 10.4187 18.1705 11.5681 18.1516 12.9575C18.1516 14.3569 17.2056 15.4963 15.9947 15.4963Z" />
            </svg>
        ),
    },
    {
        name: 'Dune Analytics',
        href: 'https://dune.com/blockworks_research',
        icon: (sProps: React.SVGProps<SVGSVGElement>) => (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...sProps}>
                <g style={{ mixBlendMode: 'luminosity' }}>
                    <path
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                        fill="#F06040"
                    />
                    <path
                        d="M2 18.9217C2 18.9217 9.95629 16.4087 23.979 12C23.979 12 24.7471 19.2199 16.9846 22.9859C16.9846 22.9859 13.1566 24.7548 8.95714 23.6201C8.95714 23.6201 4.77538 22.859 2 18.9217Z"
                        fill="#2B286C"
                    />
                </g>
            </svg>
        ),
    },
] as const;

const Socials = () => {
    return (
        <nav className="mb-4 mt-2 pl-10 flex justify-start space-x-4">
            {SOCIALS.map(item => (
                <Link
                    key={`secondary-nav-${item.name}`}
                    href={item.href}
                    className="text-gray-400 hover:text-gray-500"
                    target="_blank"
                    rel="noreferrer"
                >
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-5 w-5" aria-hidden="true" />
                </Link>
            ))}
        </nav>
    );
};

export default Socials;
