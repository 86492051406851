import * as React from 'react';

import { classNames } from '../../../utils/functions/class-names';

export type PriceChangeLabelType = {
    className?: string;
    color?: 'purple' | 'white' | '';
};

const CircleSpinner = (props: PriceChangeLabelType) => {
    const { className, color } = props;

    let textColor: string;
    switch (color) {
        case 'white':
            textColor = 'text-white';
            break;
        case 'purple':
            textColor = 'text-purple-600';
            break;
        default:
            textColor = '';
    }

    return (
        <svg
            className={classNames('animate-spin inline', textColor, className || 'h-5 w-5')}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
        >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
        </svg>
    );
};

export default CircleSpinner;
