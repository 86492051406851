import React, { Fragment, useRef, useState } from 'react';

import CircleSpinner from '@core/components/display/spinners/CircleSpinner';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Link from '@core/components/display/links/Link';
import { KnockFeedProvider, NotificationFeedPopover, NotificationIconButton } from '@knocklabs/react-notification-feed';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';

import UserProfilePicture from '@/components/image/UserProfilePicture';
import { logout } from '@/utils/api/session';
import { useUserContext } from '@/utils/contexts/UserContext';
import { classNames } from '@/utils/functions/class-names';

const SIGNIN_URL = '/sign-in';
const SIGNUP_URL = '/sign-up';

type IAuthenticatedProps = {
    session: Session;
};

const Loading = () => (
    <div>
        <CircleSpinner className="h-4 w-4 text-white" color="white" />
    </div>
);

const UnAuthenticated = () => {
    return (
        <>
            <Link href={SIGNIN_URL}>
                <button className="mx-2 text-white flex items-center cursor-pointer text-sm leading-6 focus:outline-none hover:text-gray-300">
                    Sign In
                </button>
            </Link>
            <Link href={SIGNUP_URL}>
                <button className="mx-2 px-6 py-0.5 font-bold bg-white rounded-full flex items-center text-black cursor-pointer text-sm leading-6 hover:bg-primary hover:text-white">
                    Sign Up
                </button>
            </Link>
        </>
    );
};

const Authenticated = (props: IAuthenticatedProps) => {
    const { session } = props;
    const { isLoadingUser, user, isLoggedIn } = useUserContext();
    const [isVisible, setIsVisible] = useState(false);
    const notifButtonRef = useRef(null);

    const subscribeParent = useRef(null);
    const userName = user.name || session?.user?.name || '';

    if (isLoadingUser) {
        return <Loading />;
    }

    if (!isLoggedIn) {
        return <UnAuthenticated />;
    }

    return (
        <div className="flex">
            {user && isLoggedIn && user.email && process.env.KNOCK_PUBLIC_API_KEY && (
                <KnockFeedProvider
                    apiKey={process.env.KNOCK_PUBLIC_API_KEY || ''}
                    feedId={process.env.KNOCK_FEED_CHANNEL_ID || ''}
                    userId={user.email}
                    colorMode="light"
                >
                    <>
                        <div className="my-1 mr-2 text-white">
                            <NotificationIconButton ref={notifButtonRef} onClick={() => setIsVisible(!isVisible)} />
                        </div>
                        <NotificationFeedPopover
                            buttonRef={notifButtonRef}
                            isVisible={isVisible}
                            onClose={() => setIsVisible(false)}
                        />
                    </>
                </KnockFeedProvider>
            )}
            {user && isLoggedIn && user.subscriptionStatus !== 'active' && (
                <div ref={subscribeParent} className="h-10 mr-4 pt-1">
                    <Link href="/settings/profile/plan/">
                        <button className="m-auto align-middle cursor-pointer inline-flex items-center px-2 py-1 text-sm leading-6 font-medium rounded-full text-primary font-semibold bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Subscribe
                        </button>
                    </Link>
                </div>
            )}
            <Menu as="div" className="relative z-40 shrink-0">
                <div>
                    <Menu.Button className="border border-gray-700 rounded-full max-w-xs flex items-center text-sm focus:outline-none focus:ring-1 focus:ring-gray-500 mt-1 lg:mt-0 lg:px-0.5 lg:py-0.5 lg:hover:bg-gray-800">
                        <UserProfilePicture person={user} size="small" />

                        <span className="hidden ml-3 text-white text-sm font-medium lg:block">
                            <span className="sr-only">Open user menu for </span>
                            {userName}
                        </span>
                        <ChevronDownIcon
                            className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md py-2 shadow-xs py-1 ring-1 ring-black ring-opacity-5 focus:outline-none bg-white">
                        <Menu.Item>
                            {({ active }) => (
                                <Link
                                    href="/settings/profile"
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100',
                                    )}
                                >
                                    Profile
                                </Link>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <Link
                                    href="/settings/profile/plan"
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100',
                                    )}
                                >
                                    Manage Subscription
                                </Link>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <Link
                                    href="/settings/notifications"
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100',
                                    )}
                                >
                                    Notifications
                                </Link>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={() => logout()}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100',
                                    )}
                                >
                                    Logout
                                </a>
                            )}
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
};

const UserProfile = () => {
    const { data: session, status } = useSession();
    const isAuthenticated = status === 'authenticated';
    const isLoading = status === 'loading';

    if (isLoading) {
        return <Loading />;
    }

    return <>{isAuthenticated && session !== null ? <Authenticated session={session} /> : <UnAuthenticated />}</>;
};

export default UserProfile;
