import * as React from 'react';

import { BaseItem } from '@algolia/autocomplete-core';
import Label from '@core/components/display/labels/Label';

import { classNames } from '@/utils/functions/class-names';

export type ProposalItemType = BaseItem & {
    title: string;
    assetCode: string;
    objectID: string;
    image?: string;
    category?: string;
    importance?: string;
    selected?: boolean;
    onSelect: (item: ProposalItemType) => void;
};

const ProposalItem = (props: ProposalItemType) => {
    const { assetCode, title, image, selected, onSelect } = props;

    return (
        <a
            className={classNames(
                'flex items-center space-x-4 py-2 px-4 cursor-pointer hover:bg-gray-50 md:p-4',
                selected ? 'bg-gray-100' : '',
            )}
            onClick={() => onSelect(props)}
        >
            <div className="shrink-0">
                <img alt="" src={image} className="h-8 w-8 rounded-full" />
            </div>
            <div className="w-full flex flex-col space-y-1">
                <span className="w-5/6 text-sm font-semibold text-text-dark truncate">{title}</span>
                {assetCode && (
                    <div>
                        <Label key={assetCode} className="mr-2">
                            {assetCode}
                        </Label>
                    </div>
                )}
            </div>
        </a>
    );
};

export default ProposalItem;
