declare const window: any;

type ChartbeatPlugingProps = {
    uid: string;
    domain: string;
};
type ChartbeatEventProps = { sections: string; authors: string; path: string; title: string };

/**
 * Chartbeat plugin
 *
 * @param userConfig
 * @constructor
 */
const ChartbeatPlugin = (userConfig: ChartbeatPlugingProps) => {
    let isInitialized = false;

    const sendChartbeatEvent = (properties: ChartbeatEventProps) => {
        if (!window.pSUPERFLY || !isInitialized) {
            // We should ignore initial pageview event as they will be duplicates:
            // https://docs.chartbeat.com/cbp/tracking/standard-websites/single-page-apps

            return;
        }

        window.pSUPERFLY?.virtualPage(properties);
    };

    return {
        name: 'chartbeat',
        config: userConfig,
        initialize: ({ config }: { config: ChartbeatPlugingProps }) => {
            // Do not initialize if window is not defined
            if (typeof window === 'undefined') {
                return;
            }

            // Check if chartbeat is already initialized
            if (window.pSUPERFLY) {
                return;
            }

            if (!config.uid) {
                throw new Error('uid in ChartbeatPlugin config is not defined.');
            }

            if (!config.domain) {
                throw new Error('domain in ChartbeatPlugin config is not defined.');
            }

            // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-shadow,no-underscore-dangle,no-multi-assign
            const _sf_async_config = (window._sf_async_config = window._sf_async_config || {});
            _sf_async_config.uid = config.uid;
            _sf_async_config.domain = config.domain;
            _sf_async_config.flickerControl = false;
            _sf_async_config.useCanonical = true;
            _sf_async_config.useCanonicalDomain = true;

            const section: any = document?.querySelector('meta[property="article:section"]');
            _sf_async_config.sections = section?.content || '';

            const author: any = document?.querySelector('meta[property="article:author"]');
            _sf_async_config.authors = author?.content || '';

            // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-shadow,no-underscore-dangle,no-multi-assign
            const _cbq = (window._cbq = window._cbq || []);
            _cbq.push(['_acct', 'anon']);

            const loadChartbeat = () => {
                const e = document.createElement('script');
                const n = document.getElementsByTagName('script')[0];
                if (!e || !n) {
                    return;
                }

                e.type = 'text/javascript';
                e.async = true;
                e.src = '//static.chartbeat.com/js/chartbeat.js';
                // @ts-ignore
                n.parentNode.insertBefore(e, n);

                const r = document.createElement('script');
                r.type = 'text/javascript';
                r.async = true;
                r.src = '//static.chartbeat.com/js/chartbeat_mab.js';
                r.onload = () => {
                    isInitialized = true;
                };
                // @ts-ignore
                n.parentNode.insertBefore(r, n);
            };

            loadChartbeat();
        },
        page: ({ payload }: { payload: any }) => {
            if (!payload.properties?.url) {
                return;
            }

            sendChartbeatEvent({
                sections: payload.properties?.categories?.join(',') || [],
                authors: payload.properties?.authors?.join(',') || [],
                path: payload.properties?.url,
                title: payload.properties?.title,
            });
        },
    };
};

export default ChartbeatPlugin;
