import { ResearchApiConfig } from '@research-api';

// TODO: Move into config folder
const ResearchConfig = {
    ...ResearchApiConfig,
    imgixUrl: 'https://blockworksresearch.imgix.net',
    imageBaseUrl: process.env.STRAPI_URL,
    prodBasename: 'blockworksresearch.com',
    siteUrl: process.env.SITE_URL!,
    site_name: 'Blockworks Research',
    title: 'Blockworks Research',
    description:
        'Crypto has evolved into distinct sectors: DeFi, Gaming, NFTs, Bitcoin, Stablecoins, DAOs and more. ' +
        'Blockworks Research brings you protocol and sector specialists who guide you through each area so ' +
        'you can build tailored investment strategies for each.',
    logoUrl: 'https://blockworks-research.s3.us-east-2.amazonaws.com/assets/blockworks-logo_16x9.jpg',
};

export default ResearchConfig;
