import Cookies from 'universal-cookie';

import { IRequestOptions } from './interfaces';

const fetcher = async (url: string, options?: IRequestOptions): Promise<any> => {
    try {
        const uaHeader: { 'User-Agent'?: string } = {};
        if (typeof window === 'undefined') {
            uaHeader['User-Agent'] = 'blockworks-research/1.0';
        }

        const cookies = new Cookies();
        let dynamicHeaders: Record<string, string> = { 'Content-Type': 'application/json' };

        /** 👇 for `FormData` body, content type is automagically inferred */
        if (options?.body instanceof FormData) {
            /** 👇 so let's instead set headers to: */
            dynamicHeaders = { Accept: 'application/json' };
        }

        const res = await fetch(url, {
            ...options,
            method: options?.method || 'GET',
            mode: 'cors', // no-cors, *cors, same-origin
            credentials: options?.credentials || 'include', // *include, same-origin, omitredirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // *no-referrer, client
            headers: {
                ...dynamicHeaders,
                ...(options?.headers || {}),
                ...uaHeader,
                'X-Research-Session-Token':
                    options?.sessionToken ?? cookies.get('sessionToken') ?? cookies.get('next-auth.session-token'),
            },
        });

        return await res.json();
    } catch (err) {
        console.error('research-api.fetcher error', err);
        return null;
    }
};

export default fetcher;
