import { MutableRefObject } from 'react';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import Autocomplete from '@/components/search/global/Autocomplete';

type ISearchBarProps = {
    query: string;
    refs: MutableRefObject<null>[];
    className?: string;
};

const SearchBar = (props: ISearchBarProps) => {
    const { query, refs } = props;

    return (
        <form className="h-8 w-full flex md:ml-0 rounded" action="" method="GET">
            <label htmlFor="search-field" className="sr-only">
                Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none" aria-hidden="true">
                    <MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" />
                </div>
                <Autocomplete query={query} refs={refs} className="" />
            </div>
        </form>
    );
};

export default SearchBar;
