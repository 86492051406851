import { fetcher } from '@research-api';
import { Meta } from '@research-api/utils/api/api';
import { companyEndpoints, userEndpoints } from '@research-api/utils/api/endpoints';
import returnDataOrThrow from '@research-api/utils/api/helpers/returnDataOrThrow';

import { ICompanyTeamMember, IUserProfileInfo } from '@/types/components/pages/settings/UserProfile';

// Type aliases
export type CompanyResponseMember = ICompanyTeamMember;
export type UserProfileResponse = IUserProfileInfo;

// Custom response types
export interface UserResponse {
    id: string;
    firstname: string;
    lastname: string;
    name: string;
    email: string;
    image: string;
    authProvider?: string | null;
    subscription?: string | null;
    subscriptionStatus?: string | null;
}

export interface CompanyResponse {
    name: string;
    subscriptionStatus: string;
    members: CompanyResponseMember[];
    error?: string;
}

export interface UserData {
    _meta: Meta;
    data: UserResponse[];
}

export const fetchUser = async (userId: string): Promise<UserResponse> => {
    const response = await fetcher(userEndpoints.byId(userId), { credentials: 'include' });
    return returnDataOrThrow<UserResponse>(response);
};

export const fetchUserWithSessionId = async (sessionId: string): Promise<UserResponse> => {
    const response = await fetcher(userEndpoints.bySession(sessionId));
    return returnDataOrThrow<UserResponse>(response);
};

export const fetchSessionUser = async (): Promise<UserResponse> => {
    const response = await fetcher(userEndpoints.profile, { credentials: 'include' });
    return returnDataOrThrow<UserResponse>(response) as UserResponse;
};

export const fetchCompany = async (): Promise<CompanyResponse> => {
    const response = await fetcher(companyEndpoints.company, { credentials: 'include' });
    return returnDataOrThrow<CompanyResponse>(response);
};

export const fetchUserProfile = async (): Promise<UserProfileResponse> => {
    const response = await fetcher(userEndpoints.userProfile, { credentials: 'include' });
    return returnDataOrThrow<UserProfileResponse>(response);
};
