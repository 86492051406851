import React from 'react';

import { BaseItem } from '@algolia/autocomplete-core';
import { AutocompletePlugin, AutocompleteSource, AutocompleteState } from '@algolia/autocomplete-js';
import {
    createLocalStorageRecentSearchesPlugin,
    search,
    SearchParams,
} from '@algolia/autocomplete-plugin-recent-searches';
import { MaybePromise } from '@algolia/autocomplete-shared';
import { SafeStrapiEntity, Flashnote, Article } from '@blockworks/platform/src/api/strapi/models';

import { AssetItemType } from '@/components/search/global/results/AssetItem';
import ResultsHeader from '@/components/search/global/results/ResultsHeader';

export type Highlighted<TItem> = TItem & {
    _highlightResult: {
        label: {
            value: string;
        };
    };
};

export type RecentlyViewedRecord = BaseItem & {
    sourceId: string;
    item: AssetItemType | SafeStrapiEntity<Article | Flashnote>;
};

type CreateLocalStorageRecentlyViewedItemsParams<TItem extends RecentlyViewedRecord> = {
    key: string;
    limit?: number;
    // @ts-ignore
    search?(params: SearchParams<TItem>): Array<Highlighted<TItem>>;
    transformSource?(params: {
        source: AutocompleteSource<TItem>;
        state: AutocompleteState<TItem>;
        onRemove(id: string): void;
    }): AutocompleteSource<TItem>;
};

type RecentlyViewedItemsPluginData<TItem extends RecentlyViewedRecord> = {
    addItem(item: TItem): void;
    removeItem(id: string): void;
    getAll(query?: string): MaybePromise<Array<Highlighted<TItem>>>;
};

export const createLocalStorageRecentlyViewedItems = <TItem extends RecentlyViewedRecord>(
    params: CreateLocalStorageRecentlyViewedItemsParams<TItem>,
): AutocompletePlugin<TItem, RecentlyViewedItemsPluginData<TItem>> => {
    const {
        ...plugin
        // @ts-ignore
    } = createLocalStorageRecentSearchesPlugin<TItem>({
        ...params,
        search: searchParams => {
            if (searchParams.query) {
                return [];
            }

            // @ts-ignore
            return search(searchParams);
        },
        transformSource: ({ source, onRemove, state }) => {
            const transformedSource = params.transformSource
                ? params.transformSource({ source, onRemove, state })
                : source;

            return {
                ...transformedSource,
                getItemUrl: ({ item }) =>
                    item.sourceId === 'Assets' ? `/assets/${item.code}` : `/research/${item.slug}`,
                templates: {
                    ...transformedSource.templates,
                    header: () => <ResultsHeader title="Recently Viewed" />,
                },
            };
        },
    });

    // @ts-ignore
    const { ...data } = plugin.data;

    return {
        ...plugin,
        data,
    };
};
