import * as React from 'react';

import { BaseItem } from '@algolia/autocomplete-core';
import Link from '@core/components/display/links/Link';

import { classNames } from '@/utils/functions/class-names';

export type AssetItemType = BaseItem & {
    code: string;
    image: string;
    title: string;
    objectID: string;
    category?: string;
    sector?: string;
    tagline?: string;
    selected?: boolean;
    onSelect: (item: AssetItemType) => void;
};

const AssetItem = (props: AssetItemType) => {
    const { code, image, title, selected, onSelect } = props;

    return (
        <Link
            href={`/assets/${code.toLowerCase()}`}
            className={classNames(
                'flex items-center space-x-4 py-2 px-4 cursor-pointer hover:bg-gray-50 md:p-4',
                selected ? 'bg-gray-100' : '',
            )}
            onClick={() => onSelect(props)}
        >
            <div className="shrink-0">
                <img
                    alt=""
                    src={image || `/_next/image/?url=%2Fimages%2Ftokens%2F${code.toLowerCase()}.svg&w=32&q=75`}
                    className="h-8 w-8 rounded-full"
                />
            </div>
            <div className="flex flex-col">
                <span className="text-sm font-semibold text-text-dark">{title}</span>
                <span className="text-xs font-normal text-gray-500">{code}</span>
            </div>
        </Link>
    );
};

export default AssetItem;
