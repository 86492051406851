import React from 'react';

type ILogoProps = {
    className: string;
};

const Logo = (props: ILogoProps) => (
    <svg
        className={props.className}
        width="150"
        height="52"
        viewBox="0 0 150 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1756_21025)">
            <path d="M22.6511 0.0123291H18.3511V22.9293H22.6511V0.0123291Z" fill="currentColor" />
            <path
                d="M31.7954 6.5398C27.0438 6.5398 23.4614 10.0664 23.4614 14.9728C23.4797 16.6185 23.9846 18.222 24.9123 19.5815C25.8401 20.9409 27.1493 21.9954 28.6752 22.6122C30.201 23.2291 31.8753 23.3807 33.4871 23.048C35.099 22.7153 36.5764 21.9131 37.7333 20.7425C38.8901 19.5719 39.6748 18.0851 39.9885 16.4695C40.3021 14.8538 40.1307 13.1815 39.4959 11.663C38.8611 10.1445 37.7912 8.84783 36.4209 7.93618C35.0506 7.02453 33.4413 6.53868 31.7954 6.5398V6.5398ZM31.7954 19.5018C29.698 19.5018 27.7553 17.9488 27.7553 14.9728C27.7553 11.9968 29.698 10.4624 31.7954 10.4624C33.8929 10.4624 35.8356 11.9844 35.8356 14.9852C35.8356 17.9859 33.8991 19.5018 31.7954 19.5018Z"
                fill="currentColor"
            />
            <path
                d="M48.8719 19.4646C46.6755 19.4646 44.8008 17.8498 44.8008 14.9728C44.8008 12.0958 46.6569 10.5181 48.841 10.5181C49.6481 10.4842 50.4416 10.7334 51.0844 11.2226C51.7273 11.7118 52.179 12.4102 52.3614 13.1971L56.2098 11.904C55.5292 9.22499 53.073 6.53979 48.742 6.53979C44.1202 6.53979 40.5008 10.0664 40.5008 14.9728C40.4722 16.0831 40.6687 17.1877 41.0784 18.22C41.4881 19.2523 42.1025 20.191 42.8847 20.9795C43.6669 21.768 44.6006 22.39 45.6295 22.8081C46.6585 23.2262 47.7614 23.4316 48.8719 23.412C53.1039 23.412 55.5911 20.6959 56.2964 18.0478L52.5161 16.8104C52.2955 17.5981 51.8159 18.2884 51.1547 18.77C50.4935 19.2516 49.6893 19.4963 48.8719 19.4646V19.4646Z"
                fill="currentColor"
            />
            <path
                d="M91.6063 7.0285L88.6674 17.2743L85.2397 7.0285H80.717L77.2584 17.2372L74.3133 7.0285H72.3025H69.7906H66.6784L61.1843 12.9434V0.0123291H56.8843V22.9293H61.1843V18.7283L62.96 16.8536L67.161 22.9293H72.4263L65.9298 13.7168L70.4216 9.00218L74.864 22.9293H79.2259L82.8825 12.071L86.5947 22.9293H90.8948L95.8692 7.0285H91.6063Z"
                fill="currentColor"
            />
            <path
                d="M103.3 6.53972C101.626 6.51886 99.9839 6.99618 98.5822 7.91099C97.1805 8.8258 96.0825 10.1368 95.4278 11.6772C94.773 13.2177 94.5912 14.9181 94.9053 16.5622C95.2195 18.2063 96.0155 19.7198 97.192 20.9104C98.3686 22.1009 99.8726 22.9147 101.513 23.2482C103.153 23.5818 104.856 23.42 106.404 22.7835C107.952 22.147 109.276 21.0646 110.207 19.6737C111.138 18.2829 111.635 16.6466 111.634 14.9727C111.634 10.0664 108.051 6.53972 103.3 6.53972ZM103.3 19.5017C101.196 19.5017 99.2596 17.9487 99.2596 14.9727C99.2596 11.9967 101.196 10.45 103.3 10.45C105.403 10.45 107.34 11.972 107.34 14.9727C107.34 17.9735 105.397 19.5017 103.3 19.5017Z"
                fill="currentColor"
            />
            <path
                d="M116.614 9.38579V7.0285H112.444V22.9232H116.775V15.6533C116.775 12.2257 118.681 11.1615 120.877 11.1615C121.312 11.1604 121.745 11.204 122.17 11.2914V6.96043C121.849 6.96043 121.521 6.89856 121.168 6.89856C119.776 6.89856 117.58 7.28217 116.614 9.38579Z"
                fill="currentColor"
            />
            <path
                d="M138.239 7.0285H132.614L127.12 12.9434V0.0123291H122.82V22.9293H127.12V18.7283L128.896 16.8536L133.097 22.9293H138.362L131.872 13.7168L138.239 7.0285Z"
                fill="currentColor"
            />
            <path
                d="M145.279 13.2961L143.021 12.8073C142.149 12.6465 141.567 12.1886 141.567 11.3905C141.567 10.481 142.47 9.80658 143.603 9.80658C144.23 9.75235 144.853 9.94408 145.342 10.3413C145.83 10.7384 146.145 11.3099 146.22 11.9349L149.808 11.1306C149.61 9.41678 148.094 6.53979 143.572 6.53979C140.144 6.53979 137.626 8.89708 137.626 11.7431C137.626 13.9767 139.012 15.8143 142.081 16.4948L144.184 16.9774C145.422 17.2373 145.898 17.8189 145.898 18.5304C145.898 19.3718 145.217 20.1143 143.794 20.1143C141.938 20.1143 140.985 18.9511 140.886 17.6889L137.205 18.5056C137.397 20.3123 139.061 23.4182 143.825 23.4182C147.995 23.4182 150.012 20.7639 150.012 18.1839C150 15.8452 148.416 13.9396 145.279 13.2961Z"
                fill="currentColor"
            />
            <path
                d="M69.908 51.7117H5.30853C3.90112 51.7101 2.55183 51.1503 1.55664 50.1551C0.561452 49.1599 0.00163747 47.8106 0 46.4032V37.0669C0.00163747 35.6595 0.561452 34.3102 1.55664 33.315C2.55183 32.3198 3.90112 31.76 5.30853 31.7584H69.908C71.3149 31.76 72.6635 32.32 73.6577 33.3154C74.6519 34.3107 75.2104 35.66 75.2104 37.0669V46.4218C75.2055 47.8254 74.6449 49.1699 73.6512 50.1613C72.6575 51.1526 71.3116 51.7101 69.908 51.7117V51.7117ZM5.30853 32.2657C4.67913 32.2665 4.05607 32.3914 3.47497 32.6332C2.89387 32.875 2.36613 33.229 1.92195 33.6749C1.47776 34.1208 1.12583 34.6499 0.886282 35.2319C0.646737 35.814 0.524276 36.4375 0.525902 37.0669V46.4218C0.527542 47.6891 1.03215 48.904 1.92889 49.7996C2.82563 50.6952 4.04117 51.1982 5.30853 51.1982H69.908C71.1748 51.1982 72.3897 50.695 73.2855 49.7992C74.1812 48.9035 74.6845 47.6886 74.6845 46.4218V37.0669C74.6845 35.7995 74.1814 34.584 73.2858 33.6872C72.3903 32.7905 71.1754 32.2859 69.908 32.2842L5.30853 32.2657Z"
                fill="currentColor"
            />
            <path
                d="M12.1082 46.0444H10.3882V36.3925H14.2489C16.402 36.3925 17.7446 37.401 17.7446 39.3499C17.7841 40.0097 17.5805 40.661 17.1724 41.1808C16.7642 41.7006 16.1799 42.053 15.5297 42.1713L18.0045 46.0444H16.0246L13.6673 42.3321H12.1082V46.0444ZM14.1561 40.8287C15.3935 40.8287 16.0494 40.2904 16.0494 39.3499C16.0494 38.4095 15.3935 37.8712 14.1561 37.8712H12.1082V40.8287H14.1561Z"
                fill="currentColor"
            />
            <path
                d="M18.4312 42.524C18.4312 40.4513 19.8294 38.8488 21.7907 38.8488C23.7521 38.8488 24.9214 40.3956 24.9214 42.3322V42.8704H19.9717C20.0955 44.1078 20.8194 44.8194 21.8279 44.8194C22.188 44.8438 22.5459 44.7459 22.8435 44.5415C23.1411 44.3371 23.361 44.0382 23.4675 43.6933L24.8534 44.213C24.6157 44.8154 24.1972 45.3293 23.6553 45.6839C23.1135 46.0385 22.475 46.2163 21.8279 46.1929C19.8604 46.1929 18.4312 44.7018 18.4312 42.524ZM20.0522 41.6639H23.2942C23.3028 41.4654 23.269 41.2672 23.1951 41.0827C23.1211 40.8983 23.0086 40.7317 22.8652 40.5941C22.7217 40.4565 22.5506 40.3511 22.3632 40.2848C22.1758 40.2186 21.9764 40.1931 21.7784 40.21C21.3644 40.2061 20.9627 40.3506 20.646 40.6173C20.3294 40.884 20.1187 41.2553 20.0522 41.6639V41.6639Z"
                fill="currentColor"
            />
            <path
                d="M26.6664 43.7304C26.8859 44.0748 27.1872 44.3596 27.5434 44.5593C27.8997 44.7591 28.2998 44.8675 28.7082 44.875C29.395 44.875 30.026 44.6337 30.026 44.0026C30.026 43.3715 29.4073 43.3282 28.3184 43.0993C27.2295 42.8704 25.9302 42.592 25.9302 41.0823C25.9302 39.7954 27.0562 38.8488 28.6834 38.8488C29.229 38.8239 29.7727 38.93 30.2689 39.1582C30.7651 39.3864 31.1995 39.73 31.5357 40.1604L30.4468 41.138C30.2486 40.8269 29.9739 40.5719 29.6488 40.3974C29.3238 40.223 28.9594 40.135 28.5906 40.1419C27.9348 40.1419 27.5017 40.4388 27.5017 40.9091C27.5017 41.3793 28.0152 41.5278 28.9 41.701C30.0941 41.9609 31.6038 42.2145 31.6038 43.8294C31.6038 45.2524 30.2983 46.1681 28.6958 46.1681C28.0765 46.1967 27.4593 46.0762 26.8963 45.8167C26.3332 45.5573 25.8406 45.1664 25.46 44.677L26.6664 43.7304Z"
                fill="currentColor"
            />
            <path
                d="M32.3213 42.524C32.3213 40.4513 33.7196 38.8488 35.6871 38.8488C37.6546 38.8488 38.8177 40.3956 38.8177 42.3322V42.8704H33.8681C33.9918 44.1078 34.7157 44.8194 35.7242 44.8194C36.0844 44.8438 36.4423 44.7459 36.7398 44.5415C37.0374 44.3371 37.2573 44.0382 37.3638 43.6933L38.7435 44.213C38.5109 44.8176 38.0944 45.3339 37.5526 45.6891C37.0109 46.0444 36.3714 46.2206 35.7242 46.1929C33.7381 46.1929 32.3213 44.7018 32.3213 42.524ZM33.9485 41.6639H37.1843C37.193 41.4654 37.1592 41.2672 37.0852 41.0827C37.0112 40.8983 36.8988 40.7317 36.7553 40.5941C36.6119 40.4565 36.4407 40.3511 36.2533 40.2848C36.0659 40.2186 35.8666 40.1931 35.6685 40.21C35.2556 40.2075 34.8554 40.3527 34.54 40.6193C34.2246 40.8859 34.0149 41.2564 33.9485 41.6639V41.6639Z"
                fill="currentColor"
            />
            <path
                d="M39.5171 44.0831C39.5171 42.7343 40.5256 42.1465 41.8806 41.8619L43.8295 41.4598V41.336C43.8295 40.6678 43.483 40.2471 42.623 40.2471C42.3059 40.2114 41.9872 40.2996 41.7336 40.4933C41.4799 40.687 41.3109 40.9712 41.2619 41.2865L39.746 40.9339C39.9333 40.3072 40.3251 39.7613 40.8589 39.3834C41.3928 39.0055 42.0378 38.8173 42.6911 38.8488C44.3802 38.8488 45.4072 39.6593 45.4072 41.2865V44.3244C45.4072 44.7266 45.5804 44.8503 46.0259 44.7946V46.032C44.8504 46.1681 44.2378 45.9392 43.9904 45.3576C43.7177 45.6302 43.3903 45.8417 43.0298 45.9782C42.6693 46.1148 42.2839 46.1732 41.8991 46.1496C40.5503 46.162 39.5171 45.3453 39.5171 44.0831ZM43.8481 42.6972L42.3322 43.0189C41.6455 43.1674 41.1319 43.384 41.1319 44.015C41.1319 44.6461 41.5403 44.875 42.159 44.875C43.019 44.875 43.8481 44.4172 43.8481 43.5572V42.6972Z"
                fill="currentColor"
            />
            <path
                d="M51.1056 40.5873C50.901 40.5553 50.694 40.5408 50.4869 40.544C50.2475 40.5288 50.0077 40.5645 49.7832 40.6487C49.5586 40.733 49.3545 40.864 49.1844 41.033C49.0142 41.202 48.8818 41.4052 48.796 41.6291C48.7102 41.853 48.6728 42.0927 48.6864 42.3321V46.0444H47.084V38.9849H48.6988V40.0367C48.8832 39.6886 49.1638 39.4009 49.5072 39.2078C49.8505 39.0147 50.2422 38.9245 50.6354 38.9478C50.7879 38.9399 50.9407 38.9399 51.0932 38.9478L51.1056 40.5873Z"
                fill="currentColor"
            />
            <path
                d="M55.1272 44.7946C55.5076 44.7952 55.8758 44.6605 56.166 44.4145C56.4562 44.1685 56.6494 43.8274 56.7111 43.452L58.097 44.1511C57.8899 44.7672 57.4883 45.2993 56.9528 45.6675C56.4172 46.0357 55.7766 46.2201 55.1272 46.1929C53.1102 46.1929 51.6562 44.7018 51.6562 42.5239C51.6562 40.3461 53.1102 38.8488 55.1272 38.8488C55.7671 38.816 56.4003 38.9927 56.9306 39.3522C57.461 39.7118 57.8596 40.2345 58.0661 40.841L56.7111 41.5835C56.6473 41.2086 56.4536 40.868 56.1641 40.6215C55.8745 40.3749 55.5075 40.238 55.1272 40.2347C54.0197 40.2347 53.2711 41.0823 53.2711 42.5239C53.2711 43.9655 53.995 44.7946 55.1272 44.7946Z"
                fill="currentColor"
            />
            <path
                d="M59.0186 36.3925H60.6272V39.7397C60.8645 39.4547 61.1629 39.2266 61.5002 39.0724C61.8376 38.9182 62.2053 38.8418 62.5761 38.8488C64.0549 38.8488 64.9458 39.8758 64.9458 41.3917V46.0444H63.331V41.8619C63.331 40.9895 62.9845 40.3584 62.0935 40.3584C61.894 40.3581 61.6965 40.3986 61.5132 40.4772C61.3298 40.5559 61.1644 40.6712 61.0272 40.816C60.8899 40.9608 60.7836 41.1321 60.7149 41.3193C60.6461 41.5066 60.6163 41.706 60.6272 41.9052V46.0444H59.0186V36.3925Z"
                fill="currentColor"
            />
            <path opacity="0.3" d="M1 17.1831H6.72814V11.455H1V17.1831Z" fill="currentColor" />
            <path opacity="0.2" d="M1 22.91H6.72814V17.1832H1V22.91Z" fill="currentColor" />
            <path opacity="0.8" d="M1 5.72814H6.72814V0H1V5.72814Z" fill="currentColor" />
            <path opacity="0.5" d="M1 11.455H6.72814V5.72815H1V11.455Z" fill="currentColor" />
            <path
                opacity="0.9"
                d="M11.5802 0.0424498C11.3988 0.0102908 11.2148 0 11.0309 0H6.72803V5.72685H12.4549V0.271421C12.1719 0.172371 11.8799 0.0951902 11.5802 0.0424498Z"
                fill="currentColor"
            />
            <path
                d="M16.318 5.72691C16.318 5.71919 16.3193 5.71019 16.3193 5.70119C16.3193 3.18636 14.7036 1.05359 12.4551 0.271484V5.72691"
                fill="currentColor"
            />
            <path
                opacity="0.9"
                d="M16.318 5.72693H12.4551V11.1309C14.6959 10.3514 16.3064 8.23017 16.318 5.72693Z"
                fill="currentColor"
            />
            <path
                opacity="0.8"
                d="M6.72803 5.72815V11.455L10.5652 11.4318C11.3795 11.4318 11.8619 11.3379 12.4549 11.1308V5.72815"
                fill="currentColor"
            />
            <path
                opacity="0.5"
                d="M11.7294 11.4551H11.7397C11.566 11.4396 11.3911 11.428 11.2135 11.428C11.036 11.428 6.72803 11.4551 6.72803 11.4551V17.1832H12.4549V11.5695C12.2182 11.5168 11.9764 11.4782 11.7294 11.4551Z"
                fill="currentColor"
            />
            <path
                opacity="0.8"
                d="M12.4551 11.5695V17.1832H16.9573C16.9573 14.4355 15.0304 12.1407 12.4551 11.5695Z"
                fill="currentColor"
            />
            <path
                opacity="0.3"
                d="M6.72803 17.1832V22.91H11.7294C11.9764 22.8881 12.2182 22.8496 12.4549 22.7955V17.1832"
                fill="currentColor"
            />
            <path
                opacity="0.5"
                d="M12.4551 22.7968C15.0304 22.2244 16.9573 19.9308 16.9573 17.1832H12.4551"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_1756_21025">
                <rect width="150" height="51.7118" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>
);

export default Logo;
