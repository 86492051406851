import { Fragment, MutableRefObject, ReactNode, useRef, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Link from '@core/components/display/links/Link';
import { useRouter } from 'next/router';
import Head from 'next/head';

import SvgLogo from '@/assets/images/Logo';
import Content from '@/layout/containers/DefaultContainer/Content';
import Footer from '@/layout/containers/DefaultContainer/Footer';
import MainNavigation from '@/layout/containers/DefaultContainer/MainNavigation';
import SearchBar from '@/layout/containers/DefaultContainer/SearchBar';
import Socials from '@/layout/containers/DefaultContainer/Socials';
import UserProfile from '@/layout/containers/DefaultContainer/UserProfile';
import type { FeatureFlags } from '@/types/FeatureFlags.d';

type IMainProps = {
    meta: ReactNode | JSX.Element;
    children?: any; // TODO: fix this in v2
    featureFlags?: FeatureFlags;
};

const DefaultContainer = (props: IMainProps) => {
    const { meta, children, featureFlags } = props;
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const router = useRouter();
    const searchQuery: string =
        router.query.search && typeof router.query.search === 'string' ? router.query.search : '';

    const searchParent = useRef(null);
    const userParent = useRef(null);
    const refs: MutableRefObject<null>[] = [searchParent, userParent];

    const onClickNavItem = () => {
        setSidebarOpen(false);
    };

    return (
        <div className="h-full bg-gray-100">
            <>
                {meta}
                <Head>
                    <link rel="preconnect" href="https://ak.sail-horizon.com" />
                </Head>
                <div className="min-h-full">
                    <Transition.Root show={sidebarOpen} as={Fragment}>
                        <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
                            <Transition.Child
                                as={Fragment}
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                            </Transition.Child>
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <div className="py-8 relative flex-1 flex flex-col max-w-xs w-full bg-dark">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                                            <button
                                                type="button"
                                                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                onClick={() => setSidebarOpen(false)}
                                            >
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="px-8 mb-8 flex-shrink-0 flex items-center">
                                        <Link href="/" onClick={onClickNavItem}>
                                            <SvgLogo className="h-16 w-auto text-white" />
                                        </Link>
                                    </div>
                                    <MainNavigation onClickNavItem={onClickNavItem} featureFlags={featureFlags} />
                                    <Socials />
                                    <Footer />
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 w-14" aria-hidden="true">
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </Dialog>
                    </Transition.Root>

                    {/* Static sidebar for desktop */}
                    <div className="z-10 hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
                        {/* Sidebar component, swap this element with another sidebar if you like */}
                        <div className="py-8 flex-1 flex flex-col flex-grow bg-dark overflow-y-auto">
                            <Link href="/" className="px-8 mb-4 flex items-center flex-shrink-0">
                                <SvgLogo className="h-16 w-auto text-white" />
                            </Link>
                            <MainNavigation featureFlags={featureFlags} />
                            <Socials />
                            <Footer />
                        </div>
                    </div>

                    <div className="bg-dark flex flex-col flex-1 lg:pl-64">
                        {/* Top menu bar */}
                        <div className="bg-dark h-16 relative z-10 flex flex-nowrap">
                            <button
                                type="button"
                                className="px-4 border-transparent text-text-light focus:outline-none lg:hidden"
                                onClick={() => setSidebarOpen(true)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>

                            <div className="h-16 flex-1 flex justify-between items-center space-x-4 print:hidden">
                                <div ref={searchParent} className="h-8 flex-1 flex">
                                    <SearchBar query={searchQuery} refs={refs} />
                                </div>
                                <div ref={userParent} className={'h-8 flex items-center pr-4'}>
                                    <UserProfile />
                                </div>
                            </div>
                        </div>

                        {/* todo: maybe define some content templates (e.g. different headers) */}
                        <Content className="bg-white rounded-tl-3xl">{children}</Content>
                    </div>
                </div>
            </>
        </div>
    );
};

export default DefaultContainer;
