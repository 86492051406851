import ResearchApiConfig from '../../ResearchApiConfig';

type Endpoint = () => string;
type PageEndpoint = (slug: string) => string;
type PageLimitEndpoint = (pageNum: number, limit: number, order?: string, withSparklines?: boolean) => string;
type PageBySymbolEndpoint = (symbols: string[], onlySupported?: boolean, withSparklines?: boolean) => string;
type PageByTokensEndpoint = (assetIds: number[], pageNum?: number, limit?: number, order?: string) => string;
type PageByTokenEndpoint = (assetId: number, pageNum?: number, limit?: number) => string;
type PageSupportedEndpoint = (supported: number, pageNum?: number, limit?: number, withSparklines?: boolean) => string;
type PageFilterEndpoint = (
    supported: number,
    categories: string[],
    pageNum?: number,
    limit?: number,
    withSparklines?: boolean,
    order?: string,
) => string;
type SparklinesEndpoint = (assetCodes: string[]) => string;

type GenericEndpoint = {
    all: string;
};

type AssetsEndpoints = GenericEndpoint & {
    all: string;
    paging: PageLimitEndpoint;
    supported: PageSupportedEndpoint;
    filter: PageFilterEndpoint;
    symbols: PageBySymbolEndpoint;
    asset: PageEndpoint;
    links: PageEndpoint;
    governance: PageEndpoint;
    governanceProposal: PageEndpoint;
    governanceVotes: PageEndpoint;
    governanceMeta: PageEndpoint;
    sparkline: PageEndpoint;
    sparklines: SparklinesEndpoint;
    globals: Endpoint;
    globalsHistory: PageEndpoint;
    favorite: PageEndpoint;
    favorites: string;
};

export const assetsEndpoints: AssetsEndpoints = {
    all: `${ResearchApiConfig.apiEndpoint}/api/v1/assets`,
    favorite: (code: string) => `/api/assets/${code}/favorite`,
    favorites: `${ResearchApiConfig.apiEndpoint}/api/v1/favorites`,
    paging: (pageNum: number, limit: number, order?: string, withSparklines?: boolean) =>
        `${ResearchApiConfig.apiEndpoint}/api/v1/assets?page=${pageNum}${limit > 0 ? `&limit=${limit}` : ''}&order=${
            order || ''
        }&sparklines=${withSparklines ? '1' : '0'}`,
    supported: (supported: number, pageNum?: number, limit?: number, withSparklines?: boolean, order?: string) =>
        `${ResearchApiConfig.apiEndpoint}/api/v1/assets?${pageNum !== undefined ? `page=${pageNum}` : ''}${
            limit !== undefined ? `&limit=${limit}${order && order.length > 0 ? `&order=${order}` : ''}` : ''
        }${supported === 1 ? `&supported=${supported}` : ''}&sparklines=${withSparklines ? '1' : '0'}`,
    filter: (supported: number, categories: string[], pageNum?: number, limit?: number, withSparklines?: boolean) =>
        `${ResearchApiConfig.apiEndpoint}/api/v1/assets?${pageNum !== undefined ? `page=${pageNum}` : ''}${
            supported === 1 ? `&supported=${supported}` : ''
        }${categories.length > 0 ? `&categories=${categories.join(',')}` : ''}${
            limit !== undefined ? `&limit=${limit}&sparklines=${withSparklines ? '1' : '0'}` : ''
        }`,
    symbols: (assetIds: string[], onlySupported: boolean = false, withSparklines: boolean = false) =>
        `${ResearchApiConfig.apiEndpoint}/api/v1/assets?assets=${assetIds.join(',')}&sparklines=${
            withSparklines ? '1' : '0'
        }&supported=${onlySupported ? '1' : '0'}`,
    asset: (asset: string) => `${ResearchApiConfig.apiEndpoint}/api/v1/asset/${asset}`,
    links: (asset: string) => `${ResearchApiConfig.apiEndpoint}/api/v1/links/${asset}`,
    governance: (asset: string) => `${ResearchApiConfig.apiEndpoint}/api/v1/governance/${asset}`,
    governanceProposal: (id: string) => `${ResearchApiConfig.apiEndpoint}/api/v1/governance-proposal/${id}`,
    governanceVotes: (id: string) => `${ResearchApiConfig.apiEndpoint}/api/v1/governance-votes/${id}`,
    governanceMeta: (asset: string) => `${ResearchApiConfig.apiEndpoint}/api/v1/governance-meta/${asset}`,
    sparkline: (asset: string) => `${ResearchApiConfig.apiEndpoint}/api/v1/sparkline/${asset}`,
    sparklines: (assetCodes: string[]) =>
        `${ResearchApiConfig.apiEndpoint}/api/v1/sparklines?${
            assetCodes.length > 0 ? `assets=${assetCodes.join(',')}` : ''
        }`,
    globals: () => `${ResearchApiConfig.apiEndpoint}/api/v1/globals`,
    globalsHistory: (durationInDays: string = '90') =>
        `${ResearchApiConfig.apiEndpoint}/api/v1/globals/history/${durationInDays}`,
};

type NewsEndpoints = {
    all: string;
    paging: PageLimitEndpoint;
    byTokens: PageByTokensEndpoint;
    byToken: PageByTokenEndpoint;
};

export const newsEndpoints: NewsEndpoints = {
    all: `${ResearchApiConfig.apiEndpoint}/api/v1/news`,
    paging: (pageNum: number, limit: number, order?: string) =>
        `${ResearchApiConfig.apiEndpoint}/api/v1/news?page=${pageNum}${limit > 0 ? `&limit=${limit}` : ''}${
            order && order.length > 0 ? `&order=${order}` : ''
        }`,
    byTokens: (assetIds: number[], pageNum?: number, limit?: number, order?: string) =>
        `${ResearchApiConfig.apiEndpoint}/api/v1/news?assets=${assetIds.join(',')}${
            pageNum !== undefined ? `&page=${pageNum}` : ''
        }${limit !== undefined ? `&limit=${limit}` : ''}${order && order.length > 0 ? `&order=${order}` : ''}`,
    byToken: (assetId: number, pageNum?: number, limit?: number) =>
        `${ResearchApiConfig.apiEndpoint}/api/v1/news?assets=${assetId}${
            pageNum !== undefined ? `&page=${pageNum}` : ''
        }${limit !== undefined ? `&limit=${limit}` : ''}`,
};

type GlossaryEndpointsType = {
    all: string;
};

export const glossaryEndpoints: GlossaryEndpointsType = {
    all: `${ResearchApiConfig.apiEndpoint}/api/v1/glossary`,
};

type SessionEndpointsType = {
    signup: string;
    user: string;
    login: string;
    logout: string;
    forgotPassword: string;
};

export const sessionEndpoints: SessionEndpointsType = {
    signup: `${ResearchApiConfig.apiEndpoint}/api/v1/signup`,
    user: `${ResearchApiConfig.apiEndpoint}/api/v1/user`,
    login: `${ResearchApiConfig.apiEndpoint}/api/v1/login`,
    logout: `${ResearchApiConfig.apiEndpoint}/api/v1/logout`,
    forgotPassword: `${ResearchApiConfig.apiEndpoint}/api/v1/forgot-password`,
};

type UserEndpointsType = {
    userAccount: string;
    userProfile: string;
    userImage: string;
    byId: (id: string) => string;
    bySession: (id: string) => string;
    profile: string;
};

export const userEndpoints: UserEndpointsType = {
    userAccount: `${ResearchApiConfig.apiEndpoint}/api/v1/user-account`,
    userProfile: `${ResearchApiConfig.apiEndpoint}/api/v1/user-profile`,
    userImage: `${ResearchApiConfig.apiEndpoint}/api/v1/user-image`,
    byId: id => `${ResearchApiConfig.apiEndpoint}/api/v1/user/${id}`,
    bySession: id => `${ResearchApiConfig.apiEndpoint}/api/v1/user/session/${id}`,
    profile: `${ResearchApiConfig.apiEndpoint}/api/v1/user`,
};

type CompanyEndpointsType = {
    company: string;
    member: string;
};

export const companyEndpoints: CompanyEndpointsType = {
    company: `${ResearchApiConfig.apiEndpoint}/api/v1/company`,
    member: `${ResearchApiConfig.apiEndpoint}/api/v1/company/member`,
};

type TimelineEndpointsType = {
    byToken: (id: string) => string;
};

export const timelineEndpoints: TimelineEndpointsType = {
    byToken: id => `${ResearchApiConfig.apiEndpoint}/api/v1/timeline/${id}`,
};

type WalletEndpointsType = {
    get: string;
    subscribeToPlan: (userWalletAddress: string) => string;
};

export const walletEndpoints: WalletEndpointsType = {
    get: `${ResearchApiConfig.apiEndpoint}/api/v1/wallet`,
    subscribeToPlan: userWalletAddress =>
        `${ResearchApiConfig.apiEndpoint}/api/v1/wallet/subscribe/${userWalletAddress}`,
};

type ProposalEndpoints = {
    all: string;
    categories: string;
};

export const proposalsEndpoints: ProposalEndpoints = {
    all: `${ResearchApiConfig.apiEndpoint}/api/v1/governance`,
    categories: `${ResearchApiConfig.apiEndpoint}/api/v1/governance-categories`,
};

export const podcastEndpoints = {
    allOxResearchEpisodes: `https://blockworks.co/api/podcasts/fe2cdbd0-5480-11ed-a0b3-fff47680c6ce/episodes`,
};
