const CoreConfig = {
    apiEndpoint: process.env.API_ENDPOINT,
    strapiAPIUrl: process.env.STRAPI_URL,
    strapiAPIKey: process.env.STRAPI_TOKEN,
    site_name: 'Blockworks',
    title: 'Blockworks',
    description: '',
    locale: 'en',
    timezone: 'America/New_York',
    nextSEO: {
        twitter: {
            handle: '@Blockworks_',
            site: '@Blockworks_',
            cardType: 'summary_large_image',
        },
    },
    analytics: {
        mixpanelApiKey: process.env.MIXPANEL_API_KEY,
    },
    algolia: {
        appId: process.env.ALGOLIA_APP_ID || '',
        key: process.env.ALGOLIA_KEY || '',
    },
};

export default CoreConfig;
