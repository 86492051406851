import React, { PropsWithChildren, useCallback, useEffect } from 'react';

import Analytics, { AnalyticsInstance } from 'analytics';
import { useRouter } from 'next/router';

const AnalyticsContext = React.createContext<AnalyticsInstance>(undefined!);
AnalyticsContext.displayName = 'Analytics';

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
    const result = React.useContext(AnalyticsContext);

    if (!result) {
        throw new Error('Context used outside of its Provider!');
    }

    return result;
};

export const useTrack = () => {
    const { track } = React.useContext(AnalyticsContext);
    const trackCb = useCallback<typeof track>(
        async (eventName, params) => {
            const actionName = params?.props?.action ?? '';
            await track(eventName + actionName, {
                source: params?.source === '/' ? 'frontpage' : params?.source,
                ...params,
            });
        },
        [track],
    );
    return trackCb;
};

export const usePage = () => {
    return React.useContext(AnalyticsContext).page;
};

export const useIdentify = () => {
    return React.useContext(AnalyticsContext).identify;
};

export const usePageView = ({ meta }: { meta: any }) => {
    const page = usePage();
    const router = useRouter();

    const onPageView = useCallback(
        (url: string, metadata?: any) => {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.log(`onPageView: ${url}`, metadata);
            }

            if (metadata?.authors || metadata?.tags || metadata?.categories) {
                page({
                    title: metadata?.title ?? undefined,
                    author: (metadata?.authors || []).join(','),
                    authors: metadata?.authors || [],
                    tags: metadata?.tags || [],
                    categories: metadata?.categories || [],
                    article_modified_time: metadata?.article_modified_time ?? undefined,
                    article_published_time: metadata?.article_published_time ?? undefined,
                });

                return;
            }
            page({ url });
        },
        [router.asPath],
    );

    useEffect(() => {
        onPageView(router.asPath, meta);
    }, [router.asPath]);
};

interface AnalyticsProps extends PropsWithChildren {
    app: string;
    version: string;
    registerPlugins?: (includeClientPlugins: boolean) => any[];
}

const AnalyticsProvider = ({ children, app, version = '1.0.0', registerPlugins = () => [] }: AnalyticsProps) => {
    const analytics = React.useMemo(() => {
        return Analytics({
            app,
            version,
            debug: process.env.NODE_ENV === 'development',
            plugins: registerPlugins(
                typeof window !== 'undefined' &&
                    (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development'),
            ),
        });
    }, []);

    return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsProvider;
