/**
 * @param subscriptionStatus
 */
const isCurrentlySubscribed = (subscriptionStatus: string): boolean => {
    // If there was an active subscription but it has been cancelled,
    // show plan selector again
    if (!subscriptionStatus || subscriptionStatus === 'canceled') {
        return false;
    }

    return subscriptionStatus === 'active' || subscriptionStatus === 'trialing';
};

export default isCurrentlySubscribed;
