import { useState } from 'react';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

const useDeferredRender = () => {
    const [ready, setReady] = useState(false);
    useIsomorphicLayoutEffect(() => {
        setReady(true);
    }, [setReady]);

    return ready;
};

export default useDeferredRender;
