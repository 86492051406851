import React, { createContext, useContext } from 'react';

import { IUserAccountInfo } from '@/types/components/pages/settings/UserProfile';
import { CompanyResponse } from '@/utils/api/user';
import useCompany from '@/utils/hooks/useCompany';
import useUserSession, { UserError } from '@/utils/hooks/useUserSession';
import isCurrentlySubscribed from '@/utils/lib/stripe/isCurrentlySubscribed';

interface IUserContext {
    user: IUserAccountInfo;
    company: CompanyResponse | null;
    isLoggedIn: boolean;
    userError?: UserError | null;
    isSubscribed: boolean;
    isLoadingUser: boolean;
}

const UserContext = createContext<IUserContext>({} as IUserContext);
export const UserProvider = ({ children }: { children?: React.ReactNode | JSX.Element | JSX.Element[] }) => {
    const { user, isLoggedIn, userError, isLoading: isLoadingUser } = useUserSession();
    const { company } = useCompany(user);
    const isSubscribed = isCurrentlySubscribed(user?.subscriptionStatus || '');

    return (
        <UserContext.Provider
            value={{
                user,
                isLoggedIn,
                userError,
                company,
                isSubscribed,
                isLoadingUser,
            }}
        >
            {children as React.ReactNode}
        </UserContext.Provider>
    );
};

export const useUserContext = (): IUserContext => useContext(UserContext);
