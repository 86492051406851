import * as React from 'react';

import Label from '@core/components/display/labels/Label';
import { SafeStrapiEntity, Flashnote, Article } from '@blockworks/platform/src/api/strapi/models';

import { classNames } from '@/utils/functions/class-names';

type ResearchItemType = SafeStrapiEntity<Article | Flashnote>;

type ResearchItemProps = {
    onSelect: (item: ResearchItemType) => void;
    item: ResearchItemType;
    selected: boolean;
};

const ResearchItem = (props: ResearchItemProps) => {
    const { item, selected, onSelect } = props;
    const { tags: t, featuredImage, title } = item;
    const tags = t ?? [];

    return (
        <a
            className={classNames(
                'flex items-center space-x-4 py-2 px-4 cursor-pointer hover:bg-gray-50 md:p-4',
                selected ? 'bg-gray-100' : '',
            )}
            onClick={() => onSelect(item)}
        >
            <div className="shrink-0">
                <img alt="" src={featuredImage?.formats?.thumbnail?.url} className="h-8 w-8 rounded-full" />
            </div>
            <div className="flex flex-col space-y-1">
                <span className="text-sm font-semibold text-text-dark">{title}</span>
                {tags.length > 0 && (
                    <div>
                        {tags
                            .filter(t => t.name.indexOf(':') === -1)
                            .slice(0, 4)
                            .map(tag => (
                                <Label key={tag.name} className="mr-2">
                                    {tag.name}
                                </Label>
                            ))}
                    </div>
                )}
            </div>
        </a>
    );
};

export default ResearchItem;
