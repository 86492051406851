// eslint-disable-next-line import/prefer-default-export
export enum AnalyticsEvent {
    successfulSignup = `Auth > Account:Signup`,
    successfulLogin = `Auth > Account:Login`,
    clickedNavItem = `Navigation > Click:`,
    clickedSearchBar = `Navigation > Click:Search`,
    clickedFilter = `Filter > Click:`,
    viewedPost = `Post > View:Post`,
    viewedPaywall = `Post > View:Paywall`,
    clickedPostAction = `Post > Click:`,
    viewedFlashnote = 'Flashnote > View:Flashnote',
    clickedSupportedToggle = `Filter > Click:`,
    clickedAssetTabs = `Assets > Click:`,
    clickedAssetAction = `Asset > Click:`,
    walletSignMessageRequest = 'Profile Wallet > Sign Message Request',
    walletSignMessageResponse = 'Profile Wallet > Sign Message Response',
    walletTokenConnected = 'Profile Wallet > Token Conneted',
    governanceListSort = 'Governance > List Sort',
    governanceListFilter = 'Governance > List:Filter',
    governanceProposalButtonClick = 'Governance > Proposal > Button Click',
    governanceProposalTabSelected = 'Governance > Proposal > Tab Selected',
    pageView = 'Page View',
}
