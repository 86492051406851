const Footer = () => {
    const thisYear = new Date().getFullYear();
    return (
        <div className="px-8 pl-10 text-xs text-gray-400">
            <p className="leading-4">© {thisYear} Blockworks Research.</p>
            <p className="leading-4">All rights reserved.</p>
        </div>
    );
};

export default Footer;
