import { fetcher, poster } from '@research-api';
import { sessionEndpoints } from '@research-api/utils/api/endpoints';
import { signOut } from 'next-auth/react';
import Cookies from 'universal-cookie';

/**
 * User signup
 *
 * @param email
 * @param password
 * @param firstname
 * @param lastname
 */
export const sessionSignup = async (
    email: string,
    password: string,
    firstname?: string,
    lastname?: string,
): Promise<any> => {
    return poster(
        sessionEndpoints.signup,
        JSON.stringify({
            email,
            password,
            firstname: firstname ?? '',
            lastname: lastname ?? '',
        }),
    );
};

/**
 * Clear session and logout
 */
export const logout = async (): Promise<any> => {
    try {
        const cookies = new Cookies();
        cookies.set('token', 'none', { expires: new Date(Date.now() + 5 * 1000) });
        cookies.set('sessionToken', 'none', { expires: new Date(Date.now() + 5 * 1000) });
        cookies.set('sessionId', 'none', { expires: new Date(Date.now() + 5 * 1000) });

        await fetcher(sessionEndpoints.logout);
        return await signOut();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return Promise.resolve();
};

export default sessionSignup;
