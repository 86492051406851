import { CoreConfig } from '@core';

const ResearchApiConfig = {
    ...CoreConfig,
    siteUrl: process.env.SITE_URL!,
    ghostAPIUrl: process.env.GHOST_API_URL || 'https://admin.blockworksresearch.com',
    ghostAPIKey: process.env.GHOST_API_KEY || '835fbda34fe96a4c3f71bfa21e',
    stripeKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    stripePrice: {
        quarterly: process.env.NEXT_PUBLIC_STRIPE_PRICE_QUARTERLY || '',
        yearly: process.env.NEXT_PUBLIC_STRIPE_PRICE_YEARLY || '',
        permie: process.env.NEXT_PUBLIC_STRIPE_PRICE_PERMIE || '',
    },
    alchemyRpcUrl: `https://eth-mainnet.alchemyapi.io/v2/${process.env.ALCHEMY_KEY}`,
};

export default ResearchApiConfig;
