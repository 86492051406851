import { PropsWithChildren } from 'react';

import AnalyticsProvider, { usePageView } from '@core/utils/contexts/analytics/AnalyticsProvider';
import chartbeatPlugin from '@core/utils/contexts/analytics/plugins/ChartbeatPlugin';
import mixpanelPlugin from '@analytics/mixpanel';
import googleTagManager from '@analytics/google-tag-manager';

import type { IMetaProps } from '@/layout/head/DefaultMeta';
import ResearchConfig from '@/ResearchConfig';

type AnalyticsProps = PropsWithChildren<{
    meta: IMetaProps;
}>;

const registerPlugins = (includeClientPlugins: boolean) => {
    const plugins = [];

    if (process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID) {
        plugins.push(
            googleTagManager({
                containerId: process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID,
            }),
        );
    }

    if (process.env.CHARTBEAT_UID) {
        plugins.push(
            chartbeatPlugin({
                uid: process.env.CHARTBEAT_UID || '',
                domain: 'app.blockworksresearch.com',
            }),
        );
    }
    if (includeClientPlugins) {
        if (process.env.MIXPANEL_API_KEY) {
            plugins.push(
                mixpanelPlugin({
                    pageEvent: 'Page View',
                    token: process.env.MIXPANEL_API_KEY,
                    customScriptSrc: `${ResearchConfig.siteUrl}/mp/lib.min.js`,
                    options: {
                        api_host: `${ResearchConfig.siteUrl}/mp`,
                        persistence: 'localStorage',
                        verbose: process.env.NODE_ENV === 'development',
                        debug: process.env.NODE_ENV === 'development',
                    },
                }),
            );
        }
    }
    return plugins;
};

const PageView = ({ meta }: AnalyticsProps) => {
    usePageView({ meta });
    return null;
};

const Analytics = ({ meta, children }: AnalyticsProps) => (
    <AnalyticsProvider app="blockworks-research" version="1.0.0" registerPlugins={registerPlugins}>
        {children}
        <PageView meta={meta} />
    </AnalyticsProvider>
);

export default Analytics;
