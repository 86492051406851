import { useEffect, useState } from 'react';

import { useSession } from 'next-auth/react';
import { useIdentify } from '@core/utils/contexts/analytics/AnalyticsProvider';

import { IUserAccountInfo } from '@/types/components/pages/settings/UserProfile';
import { logout } from '@/utils/api/session';
import { fetchSessionUser, UserResponse } from '@/utils/api/user';

export type UserError = {
    message: string;
};

const useUserSession = () => {
    const { data: session } = useSession();
    const identify = useIdentify();
    const firstnameLastname = (session?.user?.name || '').split(' ');

    const [isLoading, setIsLoading] = useState(true);
    const [userError, setUserError] = useState<UserError | null>(null);
    const [user, setUser] = useState<IUserAccountInfo>({
        firstname: '',
        lastname: '',
        name: '',
        email: '',
        imageUrl: '',
        authProvider: '',
    });
    const userId = session && 'userId' in session ? session.userId : '';

    const isLoggedIn = !!userId;

    useEffect(() => {
        if (!isLoggedIn) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);

        // Initially set user to what we know from our session
        setUser({
            firstname: (firstnameLastname.length > 0 ? firstnameLastname[0] : '') || '',
            lastname: firstnameLastname.length > 1 ? firstnameLastname.splice(1).join(' ') : '',
            name: session?.user?.name || '',
            email: session?.user?.email || '',
            imageUrl: '',
            authProvider: '',
            subscription: null,
            subscriptionStatus: null,
            customerId: null,
        });

        // But also fetch user information from our backend
        fetchSessionUser()
            .then((currentUser: UserResponse) => {
                if (currentUser) {
                    setUser({
                        ...currentUser,
                        authProvider: currentUser.authProvider || '',
                        imageUrl: currentUser.image,
                    });

                    // Identify user for tracking
                    identify(currentUser.id, currentUser);
                }
                setIsLoading(false);
            })
            .catch(error => {
                const message = `${error}`;
                setUserError({ message });

                if (message.includes('Session Error')) {
                    logout();
                } else {
                    setIsLoading(false);
                }
            });
    }, [userId]);

    return { user, isLoggedIn, userError, isLoading };
};

export default useUserSession;
